import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Talk duration`}</strong>{`: 20 minutes of live coding + 10 minutes of YouTube`}</p>
    <hr></hr>
    <p>{`I did a lunch&learn on Dark today at my office, because I was desperate to share.
I live-coded for about 20 minutes and we watched `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=QgimI2SnpTQ"
      }}>{`Ellen’s demo`}</a>{`.
Folks at Chop-Chop agree that Dark is freakin awesome.`}</p>
    <p>{`So many brilliant ideas. My favorite is probably the structured editor.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Code in Dark is written in the Dark editor. The structured editor doesn’t allow you to make syntax errors. In fact, Dark doesn’t even have a parser. As you type, we manipulate the AST directly, similar to Paredit, Sketch-n-Sketch, Tofu, Prune, Lamdu, and MPS.`}</p>
      <p parentName="blockquote">{`Every state of incomplete code in Dark has a valid execution semantics, similar to typed holes in Hazel. For example, as you change a function call, we maintain the memory of the old function until the new one is valid.`}</p>
      <p parentName="blockquote">{`Since every program in Dark has meaning, incomplete code doesn’t prevent complete code from working.`}</p>
      <p parentName="blockquote"><em parentName="p"><a parentName="em" {...{
            "href": "https://medium.com/darklang/how-dark-deploys-code-in-50ms-771c6dd60671#effa"
          }}>{`medium.com/darklang/how-dark-deploys-code-in-50ms#effa`}</a></em></p>
    </blockquote>
    <h3 {...{
      "id": "links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#links",
        "aria-label": "links permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Links`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=QgimI2SnpTQ"
        }}>{`Dark demo (YouTube)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=EnPodoPzimE"
        }}>{`Paul Biggar - Dark Lang and Tablecloth powered by Reason (YouTube)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/darklang/how-dark-deploys-code-in-50ms-771c6dd60671"
        }}>{`How Dark deploys code in 50ms (Medium)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/darklang/real-problems-with-functional-languages-efe668c5264a"
        }}>{`Real problems with functional languages and their influence on Dark (Medium)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      